import { createComponent } from '@lit/react';
import { Toast } from '@penneo/vega-ui';
import React from 'react';

const UIToast = createComponent({
    tagName: 'vega-toast',
    elementClass: Toast,
    react: React,
});

export default UIToast;
